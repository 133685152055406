<template>
  <div>
    <v-card class="pa-2" outlined tile>
      <div id="property-table">
        <v-card-title class="pt-1 pb-3">
          <v-row>
            <v-switch
              v-model="showOnlyAvailable"
              flat
              color="secondary"
              label="Sólo disponibles"
            ></v-switch>

            <v-col style="padding: 20px">
              <v-autocomplete
                v-model="developmentSelected"
                :items="developmentList"
                :item-text="'name'"
                :item-value="'code'"
                label="Desarrollo Seleccionado"
                @change="getButtonProperties(developmentSelected)"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                  <div v-if="checkFavorite(item)">
                    <v-icon style="margin-left: 5px" size="24" color="orange">
                      mdi-star</v-icon
                    >
                  </div>
                </template>

                <template v-slot:item="{ item }">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>

                  <v-list-item-icon>
                    <div v-if="checkFavorite(item)">
                      <v-icon size="24" color="orange"> mdi-star</v-icon>
                    </div>
                  </v-list-item-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-text-field
                dense
                rounded
                filled
                v-model="searchTextFilter"
                append-icon="mdi-magnify"
                label="Buscar..."
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <div v-if="loadingAnimation">
          <v-card width="100vw" height="90vh" elevation="0">
            <v-container fill-height>
              <v-row justify="center" align="center">
                <v-col cols="12" justify="center" align="center">
                  <v-progress-circular
                    :width="5"
                    :size="100"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
                <v-col cols="12" justify="center" align="center">
                  <div>Cargando propiedades...</div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
        <div v-else-if="!this.developmentSelected && !loadingAnimation">
          <v-card width="100vw" height="90vh" elevation="0">
            <v-container>
              <v-img
                contain
                max-height="425"
                src="../../../public/img/undraw_No_data_re_kwbl.png"
              >
              </v-img>
              <v-row justify="center" align="center">
                <v-col cols="12" justify="center" align="center">
                  <div class="title">
                    Vaya, parece que no hay propiedades por mostrar.
                  </div>
                </v-col>
                <v-col cols="12" justify="center" align="center">
                  <div class="subtitle-2 grey--text">
                    No se encontraron propiedades, intenta recargando.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
        <div v-else>
          <v-data-table
            :sort-by="['code']"
            :sort-desc="[false]"
            dense
            :headers="headers"
            :items="properties"
            :items-per-page="15"
            class="elevation-1 mx-2"
            :loading="loading"
            loading-text="Cargando propiedades ..."
            :footer-props="{
              showFirstLastPage: true,

              'items-per-page-text': 'Filas por página:',
              'items-per-page-options': [15, 30, 50, -1]
            }"
          >
            <template v-slot:[`item.contract_status`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.contract_status"
                  large
                  @save="
                    updateProperty(item._id, {
                      contract_status: contractOption_status
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    <v-chip
                      label
                      class="property-contratct-status-tag my-1"
                      :class="
                        item && item.contract_status
                          ? item.contract_status.toLowerCase()
                          : 'vendido'
                      "
                      >{{ item.contract_status }}
                    </v-chip>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 subtitle">Actualizar</div>
                    <property-select-contract-status
                      v-model="item.contract_status"
                      @getOption="getContractStatusOption"
                      :selected="item.contract_status"
                    />
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                <v-chip
                  label
                  class="property-contratct-status-tag my-1"
                  :class="
                    item && item.contract_status
                      ? item.contract_status.toLowerCase()
                      : 'vendido'
                  "
                  >{{ item.contract_status }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.code`]="{ item }">
              <div class="subtilte">
                <div v-if="role === 'admin' || role === 'coordinator'">
                  <v-row>
                    <v-edit-dialog
                      :return-value.sync="item.code"
                      large
                      @save="
                        updateProperty(item._id, {
                          code: item.code
                        })
                      "
                      @cancel="cancel"
                      @open="open"
                    >
                      <div>
                        <span v-if="item.code">
                          <div>
                            {{ item.code }}
                          </div>
                        </span>
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">Actualizar ID</div>
                        <span>ID/Nombre</span>
                        <v-text-field
                          v-model="item.code"
                          :disabled="
                            role !== 'admin' || role !== 'coordinator'
                              ? false
                              : true
                          "
                          label="Edit"
                          single-line
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <v-edit-dialog
                      :return-value.sync="item.miscellaneous.mapping_coords"
                      large
                      @save="
                        updateProperty(item._id, {
                          miscellaneous_mappingcoords:
                            item.miscellaneous.mapping_coords
                        })
                      "
                      @cancel="cancel"
                      @open="open"
                    >
                      <div>
                        <span v-if="item.code">
                          <div>
                            <v-icon
                              small
                              class="margin-icon-code"
                              :color="getColor(item)"
                            >
                              mdi-vector-square-edit
                            </v-icon>
                          </div>
                        </span>
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">Actualizar Coordenadas</div>
                        <span>Mapping Coords {{ role }}</span>
                        <v-text-field
                          v-model="item.miscellaneous.mapping_coords"
                          :disabled="
                            role !== 'admin' || role !== 'coordinator'
                              ? false
                              : true
                          "
                          label="Edit"
                          single-line
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-row>
                </div>
                <div v-else>
                  <span v-if="item.code">
                    <div>
                      {{ item.code }}
                    </div>
                  </span>
                </div>
              </div>
            </template>
            <template v-slot:[`item.pricing.price`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.pricing.price"
                  large
                  @save="
                    updateProperty(item._id, {
                      price: item.pricing.price
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    {{
                      priceFormat(
                        item.pricing.price,
                        item.pricing.currency,
                        item.contract_status
                      )
                    }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 subtitle">Actualizar</div>
                    <v-text-field
                      v-model="item.pricing.price"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                {{
                  priceFormat(
                    item.pricing.price,
                    item.pricing.currency,
                    item.contract_status
                  )
                }}
              </div>
            </template>
            <template v-slot:[`item.features.rooms`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.features.rooms"
                  large
                  @save="
                    updateProperty(item._id, {
                      features_rooms: item.features.rooms
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    {{ item.features.rooms }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 subtitle">Actualizar</div>

                    <v-text-field
                      v-model="item.features.rooms"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                {{ item.features.rooms }}
              </div>
            </template>
            <template v-slot:[`item.features.bathrooms`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.features.bathrooms"
                  large
                  @save="
                    updateProperty(item._id, {
                      features_bathrooms: item.features.bathrooms
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    {{ item.features.bathrooms }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 subtitle">Actualizar</div>
                    <v-text-field
                      v-model="item.features.bathrooms"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                {{ item.features.bathrooms }}
              </div>
            </template>
            <template v-slot:[`item.building.type`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.building.type"
                  large
                  @save="
                    updateProperty(item._id, {
                      building_type: item.building.type
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    {{ item.building.type }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 subtitle">Actualizar</div>

                    <v-text-field
                      v-model="item.building.type"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                {{ item.building.type }}
              </div>
            </template>
            <template v-slot:[`item.building.total`]="{ item }">
              <div v-if="role === 'admin' || role === 'coordinator'">
                <v-edit-dialog
                  :return-value.sync="item.building.total"
                  large
                  @save="
                    updateProperty(item._id, {
                      building_construction:
                        item.building && item.building.construction
                          ? item.building.construction
                          : '0',
                      building_terrace:
                        item.building && item.building.terrace
                          ? item.building.terrace
                          : '0',
                      building_total:
                        item.building && item.building.total
                          ? item.building.total
                          : '0'
                    })
                  "
                  @cancel="cancel"
                  @open="open"
                >
                  <div>
                    <span
                      v-if="
                        item.building &&
                          item.building.total &&
                          item.building.total > 0
                      "
                    >
                      {{ item.building.total }} m<sup>2</sup>
                    </span>
                    <span v-else>
                      {{ getTotalM2(item.building) }} m<sup>2</sup>
                    </span>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 title">Actualizar</div>
                    <span>Construcción</span>
                    <v-text-field
                      v-model="item.building.construction"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                    <span>Terraza</span>
                    <v-text-field
                      v-model="item.building.terrace"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                    <span>Total</span>
                    <v-text-field
                      v-model="item.building.total"
                      :disabled="
                        role !== 'admin' || role !== 'coordinator'
                          ? false
                          : true
                      "
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-else>
                <span
                  v-if="
                    item.building &&
                      item.building.total &&
                      item.building.total > 0
                  "
                >
                  {{ item.building.total }} m<sup>2</sup>
                </span>
                <span v-else>
                  {{ getTotalM2(item.building) }} m<sup>2</sup>
                </span>
              </div>
            </template>
            <template v-slot:[`item.pricePerSquareMeter`]="{ item }">
              <div>
                <span
                  v-if="
                    getSquareMeterPrice(
                      getSafeNestedField(() => item.pricing.price, ''),
                      getSafeNestedField(() => item.pricing.currency, ''),
                      getSafeNestedField(() => item.building.construction, ''),
                      getSafeNestedField(() => item.building.terrace, ''),
                      getSafeNestedField(() => item.building.total, '')
                    )
                  "
                >
                  $
                  {{
                    getSquareMeterPrice(
                      getSafeNestedField(() => item.pricing.price, ""),
                      getSafeNestedField(() => item.pricing.currency, ""),
                      getSafeNestedField(() => item.building.construction, ""),
                      getSafeNestedField(() => item.building.terrace, ""),
                      getSafeNestedField(() => item.building.total, "")
                    )
                  }}
                  m<sup>2</sup>
                </span>
              </div>
            </template>

            <template v-slot:[`item.quote`]="{ item }">
              <v-btn
                icon
                color="#2C3E50"
                @click="actions(item)"
                style="padding:10px"
                :disabled="developmentSelectedConfig"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { GetRealEstateDevelopmentList } from "@/api/real-estate-development-api.js";

import moment from "moment";
moment.locale("es");

export default {
  name: "TableByDevelopment",
  components: {
    PropertySelectContractStatus: () =>
      import("@/components/property/PropertySelectContractStatus.vue")
  },
  data() {
    return {
      //TypeOfQuote..
      typeOfQuote: "one",
      loadingAnimation: true,
      developmentSelectedConfig: true,
      developmentSelectedTemp: false,
      developmentSelected: false,
      developmentList: [],
      headers: [
        { text: "Status", value: "contract_status", width: "120px" },
        { text: "ID", width: "100", value: "code" },
        {
          text: "Desarrollo",
          value: "real_estate_development.name"
        },
        { text: "Precio", value: "pricing.price" },
        //Validar que campos son necesarios...
        {
          text: "#Recs.",
          value: "features.rooms"
        },
        {
          text: "#Baños",
          value: "features.bathrooms"
        },
        {
          text: "Planta",
          value: "floor.name",
          width: "120px"
        },
        {
          text: "TDP",
          value: "building.type"
        },
        { text: "M2", value: "building.total" },
        { text: "Precio x M2", value: "pricePerSquareMeter", sortable: false }
      ],
      search: "",
      snack: false,
      snackColor: "",
      snackText: "",
      contractOption_status: "",
      role: localStorage.getItem("user_role"),
      moduleQuote: localStorage.getItem("moduleQuote")
    };
  },
  computed: {
    ...mapState({
      loading: state => state.properties.loading,
      property: state => state.properties.actualItem
    }),
    ...mapGetters("properties", {
      properties: "filteredItems"
    }),
    showOnlyAvailable: {
      get() {
        return this.$store.state.properties.filterOnlyAvailable;
      },
      set(newValue) {
        this.$store.commit("properties/setFilterOnlyAvailable", newValue);
      }
    },
    searchTextFilter: {
      get() {
        return this.$store.state.properties.filterText;
      },
      set(newValue) {
        this.$store.commit(
          "properties/setFilterText",
          newValue.trim().toLowerCase()
        );
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    }
  },
  created() {
    this.consultRealEstateDevelopmentList();
    //this.consultProperties();
    this.checkAccesQuote();
    //Creación de las columnas para las tablas...
  },

  methods: {
    ...mapActions({
      fetchProperties: "properties/fetchProperties",
      fetchUpdatelProperty: "properties/fetchUpdatelProperty"
    }),
    ...mapMutations({
      setLoading: "properties/setLoading",
      updateActualProperty: "properties/updateActualProperty"
    }),
    //Nueva función para poder crear las columnas..
    checkFavorite(item) {
      let favorite = false;
      if (item.favorite) {
        if (item.favorite === true) {
          favorite = true;
        }
      }
      return favorite;
    },
    checkAccesQuote() {
      if (this.role === "broker") {
        if (this.moduleQuote) {
          if (this.moduleQuote === "on") {
            this.headers.push({ text: "Acciones", value: "quote" });
          }
        }
      }
      if (this.role === "admin") {
        this.headers.push({ text: "Acciones", value: "quote" });
      }
    },
    consultRealEstateDevelopmentList() {
      let self = this;

      setTimeout(() => {
        GetRealEstateDevelopmentList().then(res => {
          self.developmentList = res.RealEstateDevelopment;

          self.consultProperties();
        });
      }, 100);
    },
    actions(item) {
      //Verificar si es Admin o es broker...
      let developmentTemp;
      //Verificar si es admin o es broker..
      if (this.developmentSelected.code) {
        developmentTemp = this.developmentSelected.code;
      } else {
        developmentTemp = this.developmentSelected;
      }
      let quote;
      if (this.typeOfQuote === "one") {
        quote = "quote";
      }
      if (this.typeOfQuote === "two") {
        quote = "quoteAdvanced";
      }
      this.$router.push(
        `/${this.role}/real-estate-development/${quote}/${developmentTemp}/${item._id}`
      );
    },
    getColor(item) {
      if (
        item.miscellaneous &&
        item.miscellaneous.mapping_coords &&
        item.miscellaneous.mapping_coords.length > 0
      ) {
        return "";
      } else {
        return "grey lighten-2";
      }
    },
    priceFormat(price, currency, status) {
      if (parseFloat(price) <= 0 || isNaN(price) || status === "vendido") {
        return "-";
      }
      return (
        "$" +
        parseFloat(price)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .concat(" " + currency)
      );
    },
    getSquareMeterPrice(price, currency, construction, terrace, total) {
      if (!price || price === 0 || !currency) {
        return "";
      } else {
        if (total) {
          const smp =
            total === 0
              ? ""
              : parseFloat(price / total)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .concat(" " + currency);
          return smp;
        } else if (construction && terrace) {
          let result = parseFloat(construction) + parseFloat(terrace);
          result.toFixed(2);
          return result < 1
            ? ""
            : parseFloat(price / result)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .concat(" " + currency);
        } else if (construction) {
          return construction < 1
            ? ""
            : parseFloat(price / construction)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .concat(" " + currency);
        } else if (terrace) {
          return terrace < 1
            ? ""
            : parseFloat(price / terrace)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .concat(" " + currency);
        } else {
          return "";
        }
      }
    },
    //Modificar la entrada por aqui...
    //Se puede entrar por los props y por la ruta..
    //Tener en cuenta que en el cotizador necesita poder pasar datos de la propiedad...
    consultProperties() {
      let self = this;
      self.setLoading(true);

      setTimeout(() => {
        self.developmentSelected = this.firsMove();
        self.developmentSelectedTemp = self.developmentSelected;
        if (self.developmentSelectedTemp) {
          this.fetchProperties(self.developmentSelected.code)
            .then(() => {})
            .finally(() => {
              setTimeout(() => {
                self.setLoading(false);
                self.loadingAnimation = false;
              }, 500);
              //Vamos a disparar un metodo para que ejecute activar el boton de la calculadora
              //Se le tiene que pasar el desarrollo y no las propiedades del desarrollo
              self.activateCalculator(self.developmentSelectedTemp);
            });
        }
        self.loadingAnimation = false;
      }, 1500);
    },
    //Recibira una propiedad para validar si activar o no la calculadora
    activateCalculator(desarrollo) {
      this.updateConfigDevelopment(desarrollo);
    },
    //Ultimo en terminar .... Pendiente....
    updateConfigDevelopment(selected) {
      this.developmentSelectedConfig = true;
      if (selected) {
        if (selected.miscellaneous) {
          if (selected.miscellaneous.quote) {
            if (selected.miscellaneous.quote.active) {
              if (selected.miscellaneous.quote.active === "on") {
                this.developmentSelectedConfig = false;
                this.typeOfQuote = "one";
              }
            }

            if (selected.miscellaneous.quote.active_two) {
              if (selected.miscellaneous.quote.active_two === "on") {
                this.developmentSelectedConfig = false;
                this.typeOfQuote = "two";
              }
            }
          }
        }
      }
    },
    //Aqui va el codigo para poder saber cual es la primera seleccion de las propiedades..
    firsMove() {
      //Verificar si hay un desarrollo en la ruta... de lo contrario se selecciona la primera opcion de la lista de desarrollos..
      //Tener en cuenta que prodria ser una de las propiedades en favoritos.. de ser asi debe de escoger el primero de la lista..
      if (this.$route.params.realEstateCode !== undefined) {
        //Buscar y traer el objeto...
        return this.searchProperty(this.$route.params.realEstateCode);
        ///Validar por si se pone la direccion incorrecta...
      } else {
        //Checar favoritos...
        return this.developmentList[0];
      }
    },

    searchProperty(find) {
      const result = this.developmentList.filter(l => {
        if (l.code === find) {
          return l;
        }
      });

      if (result.length !== 0) {
        return result[0];
      } else {
        return false;
      }
    },

    getButtonProperties(item) {
      if (item) {
        this.developmentSelectedTemp = this.searchProperty(item);
        this.updateConfigDevelopment(this.developmentSelectedTemp);
        this.consultPropertiesUpdate(item);
      } else {
        this.developmentSelected = this.developmentSelectedTemp;
        //Se selecciona el ultimo desarrollo que escogio
      }
    },

    consultPropertiesUpdate(code) {
      let self = this;

      self.setLoading(true);
      self.loadingAnimation = true;
      setTimeout(() => {
        this.fetchProperties(code)
          .then(() => {})
          .finally(() => {
            setTimeout(() => {
              self.setLoading(false);
              self.loadingAnimation = false;
            }, 500);
          });
      }, 1500);
    },

    updatePropertyList() {
      this.consultProperties();
    },
    updateProperty(propertyId, data) {
      this.handleUpdateProperty(propertyId, data);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    handleUpdateProperty(propertyId, propertyData) {
      // una vez cambiado la propiedad actual, actualizar el valor en Vuex
      let data = {
        propertyId,
        property: propertyData
      };

      let self = this;
      this.$snotify.async(
        "Actualizando propiedad",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.fetchUpdatelProperty(data)
                .then(() => {
                  resolve({
                    title: "Propiedad editada",
                    body: "La propiedad se ha editado correctamente.",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                  if (propertyData.contract_status !== undefined) {
                    this.loadingAnimation = true;
                    this.showOnlyAvailable = !this.showOnlyAvailable;
                    setTimeout(() => {
                      this.loadingAnimation = false;
                      this.showOnlyAvailable = !this.showOnlyAvailable;
                    }, 100);

                    this.contractOption_status = undefined;
                  }
                  //momentaneo
                  // location.reload();
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema al actualizar la propiedad. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    self.setLoading(false);
                  }, 1000);
                });
            }, 500);
          })
      );
    },
    getContractStatusOption(option) {
      const self = this;
      if (option) {
        self.contractOption_status = option.value;
      }
      // this.updateProperty(propertyId, { contract_status: option });
    },
    // end listeners
    // pasar a una librería
    getSafeNestedField(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    getTotalM2(item) {
      if (item.construction && item.terrace) {
        let result = parseFloat(item.construction) + parseFloat(item.terrace);
        return result.toFixed(2);
      } else if (item.construction) {
        return item.construction;
      } else if (item.terrace) {
        return item.terrace;
      } else {
        return 0;
      }
    }
  }
};
</script>
<style>
.theme--light.v-list-item .v-list-item__mask {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 0px !important;
}
.property-contratct-status-tag:hover {
  cursor: pointer;
}
.property-contratct-status-tag.disponible {
  text-transform: uppercase;
  background-color: green !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.disponible:hover {
  text-transform: uppercase;
  background-color: green !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.reservado {
  text-transform: uppercase;
  background-color: orange !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.reservado:hover {
  text-transform: uppercase;
  background-color: orange !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.vendido {
  text-transform: uppercase;
  background-color: #f84b57 !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.vendido:hover {
  text-transform: uppercase;
  background-color: #f84b57 !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.indefinido {
  text-transform: uppercase;
  font-weight: bold;
}
.property-contratct-status-tag.indefinido:hover {
  text-transform: uppercase;
  font-weight: bold;
}
.margin-icon-code {
  margin-left: 2px;
}
</style>
